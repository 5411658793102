.App {
  text-align: center;
}

.header {
  background-color: rgb(183, 233, 183);
  color: black;
  width: 100vw;
  height: 150px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.headertext {
  text-align: center;
  font-family: 'Lexend Deca', sans-serif;
}

.link:hover {
  text-shadow: 1px 1px 0px;
}

.title {
  font-size: 34px;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 12px;
}

.linkbar {
  display: flex;
  justify-content: space-evenly;
  font-size: 20px;
  font-family: 'Lexend Deca', sans-serif;
}

.aboutheader {
  background-image: url('../img/cobbles.jpg');
  padding-top: 15px;
  min-height: 130px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 1.6;
}

.worksheader {
  background-image: url('../img/longbeach.jpg');
  padding-top: 15px;
  min-height: 130px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.contactheader {
  background-image: url('../img/laptop.jpg');
  padding-top: 15px;
  min-height: 130px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.abouttext {
  height: auto;
}

.workstext {
  height: auto;
}

.contacttext {
  height: 100vh;
}

.aboutheadertext {
  font-family: 'Liu Jian Mao Cao', cursive;
  color: white;
  font-size: 60px;
}

.worksheadertext {
  font-family: 'Liu Jian Mao Cao', cursive;
  color: white;
  font-size: 60px;
}

.contactheadertext {
  font-family: 'Liu Jian Mao Cao', cursive;
  color: white;
  font-size: 60px;
}

.contactinfo {
  color: black;
  font-size: 34px;
  font-weight: bold;
  text-align: center;
}

.contactinfo:hover {
  text-decoration: none;
}

.contactcontainer {
  padding-top: 100px;
}

.footer {
  border: 1px black;
  display: flex;
  justify-content: space-evenly;
  background-color: white;
  color: black;
  width: 100vw;
  height: auto;
  position: -webkit-sticky;
  position: fixed;
  bottom: 0;
}

.footerlink {
  color: black;
  font-size: 10px;
  font-family: 'Lexend Deca', sans-serif;
  text-decoration: none;
}

.footerlink:hover {
  text-decoration: none;
}

.picture {
  padding: 20px;
  float: left;
  width: 150px;
  height: auto;
}

.aboutbody {
  font-size: 18px;
  padding: 20px;
}
.smlinks {
  padding-bottom: 5px;
}

.smlink {
  height: 100px;
  width: auto;
  padding: 5px;
}

@media (max-width: 400px) {
  .smlink {
    padding: 0px;
  }
}
